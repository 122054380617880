.home-image {
  height: 52%;
  width: 100%;
  object-fit: cover;
}
.home-page{
  max-width: max-content;
}
.home-images {
  width: 100%;
  height: 485px !important;
  object-fit: cover;
}

.home-subimage {
  height: 235px;
  object-fit: cover;
  width: 100%; /* Ensure full width on smaller screens */
}

/* Adjust heading spacing */
.homepage-heading {
  font-weight: bolder;
  margin-top: 8%;
  margin-bottom: 3%;
  font-size: 2rem; /* Make heading font-size responsive */
}

/* Ensure 'View' button container is centered and doesn't overflow */
.home-page-view {
  text-align: center;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.view-image {
  width: 100%; /* Make it responsive */
  position: relative;
  max-width: 300px; /* Max width to maintain design consistency */
}

.view-images {
  height: 300px;
  width: 100% !important;
  object-fit: cover;
}

.view-button {
  background-color: black !important;
  color: white;
  font-size: 15px !important;
  white-space: nowrap;
  border: none !important;
  padding: 10px 20px; /* Add padding for better look */
}

/* Responsive carousel container */
.carousal-container {
  width: 100%;
}

.heghttest {
  width: 100%; /* Ensure it takes full width */
  max-width: 100%; 
}

.heghttest img {
  width: 100%;
  height: 300px;
  object-fit: cover; /* Ensure it doesn't stretch or overflow */
}
/* Responsive card adjustments for smaller screens */
@media (max-width: 768px) {
  .view-image {
    max-width: 100%; /* Make cards more flexible */
    margin: auto; /* Center the cards */
  }

  .view-button {
    padding: 8px 14px;
    font-size: 14px; /* Adjust button font */
  }

  .heghttest img {
    height: 220px; /* Adjust carousel image height for medium screens */
  }
}

/* For very small screens, reduce font size and improve spacing */
@media (max-width: 576px) {
  .home-page {
    padding: 0 10px;
  }

  .homepage-heading {
    font-size: 1.5rem;
  }

  .view-image {
    max-width: 100%; /* Full width for mobile */
  }

  .view-button {
    padding: 6px 12px;
    font-size: 13px; /* Smaller font */
  }

  .heghttest img {
    height: 180px; /* Reduce carousel image height */
  }
}
.carousel-control-prev,
.carousel-control-next {
  display: none;
}





.image-container {
  position: relative;
  display: inline-block;
}


.add-to-cart-btn {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  color: white !important;
  border: none !important;
}

.image-container:hover .add-to-cart-btn {
  opacity: 1;
}

.carousals-image {
 margin-left: 50px !important; /* Adjust the value as needed */
}





.carousel-image .image-container {
  position: relative;
  overflow: hidden;
}

.product-image {
  width: 100%;
  height: 300px !important;
}

.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .overlay {
  opacity: 1; /* Show overlay on hover */
}

.add-to-cart-btn {
  color: white;
  background-color:black !important;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 15px !important;
}

.side-icons {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: white !important;
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  color: #000 !important;
  font-size: 24pxv !important;
}


.carousel-image {
  margin-right: 30px; /* Adjust the spacing as needed */
}

.image-container {
  position: relative;
  width: 100%;
}

.product-image {
  width: 100%;
  height: auto;
}

.add-to-cart-btn,
.wishlist-btn {
  border-radius: 50%;
  position: absolute;
  top: 10px;
  padding: 5px 10px;
  font-size: 15px;
  z-index: 2;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.add-to-cart-btn {
  left: 10px;
}

.wishlist-btn {
  right: 10px;
}

.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-align: center;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.price {
  font-size: 16px;
  margin-bottom: 20px;
}

.buy-now-btn {
  width:150px;
  padding: 5px 10px;
  background-color: #00A550;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 10px;
}

.image-container:hover .overlay {
  opacity: 1;
}

.carousel-image {
  margin: 0 10px !important; /* Adjust the space as needed */
}

.viewAllproducts{
  text-decoration: none !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
  background-color: #00A550 !important;
  border:none !important;
  width:350px;
  margin-top: 50px;
  border-radius: 10px;
}
.view-buttondiv{
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}

.bhakshanangal-qoute{
  font-size: 2.5rem !important;
  color:whitesmoke !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}
.image-bhakshanangal{
  justify-content: center;
  align-items: center;
  display: flex;
}
.bhakshanagal-quote-image{
  width:350px
}
.bhakshanangal-image{
  background: linear-gradient(to right, #fbc02d, #fff59d);
}
.name{
  color:white;
}
.price{
  color:white;
}

.home-heart-icon{
  color:grey
}

.home-heart-icon:hover{
  color:rgb(235, 17, 17)
}

.home-cart-icon{
  color:white
}
.home-cart-icon:hover{
  color:grey
}

















/* Base CSS for all screen sizes */
.home-image {
  height: 52%;
  width: 100%;
  object-fit: cover;
}

.home-page {
  max-width: max-content;
  padding: 0 15px; /* Added padding for smaller screens */
}

.home-images {
  width: 100%;
  height: 485px !important;
  object-fit: cover;
}

.home-subimage {
  height: 235px;
  object-fit: cover;
  width: 100%; /* Ensure full width on smaller screens */
}

/* Adjust heading spacing */
.homepage-heading {
  font-weight: bolder;
  margin-top: 8%;
  margin-bottom: 3%;
  font-size: 2rem;
  text-align: center; /* Center the heading */
}

/* Ensure 'View' button container is centered and doesn't overflow */
.home-page-view {
  text-align: center;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.view-image {
  width: 100%;
  position: relative;
  max-width: 300px;
}

.view-images {
  height: 300px;
  width: 100% !important;
  object-fit: cover;
}

.view-button {
  background-color: black !important;
  color: white;
  font-size: 15px !important;
  white-space: nowrap;
  border: none !important;
  padding: 10px 20px;
  border-radius: 5px;
}

/* Responsive carousel container */
.carousal-container {
  width: 100%;
}

.heghttest {
  width: 100%;
  max-width: 100%;
}

.heghttest img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

/* Media queries for different screen sizes */
@media (max-width: 768px) {
  .view-image {
    max-width: 100%; /* Make cards more flexible */
    margin: auto; /* Center the cards */
  }

  .view-button {
    padding: 8px 14px;
    font-size: 14px;
  }

  .heghttest img {
    height: 220px; /* Adjust carousel image height for medium screens */
  }

  .homepage-heading {
    font-size: 1.8rem; /* Adjust heading font-size */
  }
}

/* For very small screens (mobile view) */
@media (max-width: 576px) {
  .home-page {
    padding: 0 10px;
  }

  .homepage-heading {
    font-size: 1.5rem;
  }

  .view-button {
    padding: 6px 12px;
    font-size: 13px; /* Smaller font */
  }

  .heghttest img {
    height: 180px; /* Reduce carousel image height */
  }

  .view-image {
    max-width: 100%;
    margin: auto;
  }

  .image-bhakshanangal {
    padding: 20px 0;
  }
}

/* Hide carousel controls on smaller screens */
.carousel-control-prev,
.carousel-control-next {
  display: none;
}

/* Image hover effects */
.image-container {
  position: relative;
  display: inline-block;
}

.add-to-cart-btn {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  color: white;
  background-color: black !important;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.image-container:hover .add-to-cart-btn {
  opacity: 1;
}

/* Product image and overlay */
.product-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .overlay {
  opacity: 1;
}

/* Buttons */
.add-to-cart-btn,
.wishlist-btn {
  border-radius: 50%;
  position: absolute;
  top: 10px;
  padding: 5px 10px;
  font-size: 15px;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.add-to-cart-btn {
  left: 10px;
}

.wishlist-btn {
  right: 10px;
}

.price {
  font-size: 16px;
  margin-bottom: 20px;
}

.buy-now-btn {
  width: 150px;
  padding: 5px 10px;
  background-color: #00A550;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 10px;
}

/* View all button */
.viewAllproducts {
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: #00A550;
  border: none;
  width: 350px;
  margin-top: 50px;
  border-radius: 10px;
}

.view-buttondiv {
  text-align: center;
  display: flex;
  justify-content: center;
}

.bhakshanangal-qoute {
  font-size: 2.5rem;
  color: whitesmoke;
  text-align: center;
}

.image-bhakshanangal {
  justify-content: center;
  align-items: center;
  display: flex;
}

.bhakshanagal-quote-image {
  width: 350px;
}

.bhakshanangal-image {
  background: linear-gradient(to right, #fbc02d, #fff59d);
}

.name,
.price {
  color: white;
}

.home-heart-icon {
  color: grey;
}

.home-heart-icon:hover {
  color: rgb(235, 17, 17);
}

.home-cart-icon {
  color: white;
}

.home-cart-icon:hover {
  color: grey;
}
