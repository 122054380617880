.delivering-address{
    background-color: rgb(223, 204, 179);
    color:#121112;
    width:100% !important;
    height: 50px !important;
    display: flex;
    align-items: center; /* Ensures proper vertical alignment */
    justify-content: flex-start; /* Aligns content to the start */
  }
  
  
  .delivering-to {
    margin: 0; /* Removes default margin */
    padding: 0; /* Removes default padding */
    text-align: left; /* Ensures text is aligned to the left */
  }
  .form-choose-address{
    height:150px !important
  }
  .gift-img{
    width:100px !important;
    height: 80px !important;
  }