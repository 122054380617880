.ezy__epgrid5 {
  /* Bootstrap variables */
  --bs-body-color: #23262f;
  --bs-body-bg: #ffffff;

  /* Easy Frontend variables */
  --ezy-theme-color: rgb(13, 110, 253);
  --ezy-theme-color-rgb: 13, 110, 253;
  --ezy-card-bg: #fff;
  --ezy-img-bg: #edf8eb;
  --ezy-icon-bg: #f6f8fa;
  --ezy-icon-color: #23262f;
  --ezy-button-color: #fff;
  --ezy-rating-color: #eec200;

  background-color: var(--bs-body-bg);
  padding: 60px 0;
}

@media (min-width: 768px) {
  .ezy__epgrid5 {
    padding: 100px 0;
  }
}

/* Gray Block Style */
.gray .ezy__epgrid5,
.ezy__epgrid5.gray {
  /* Bootstrap variables */
  --bs-body-bg: rgb(246, 246, 246);
}

/* Dark Gray Block Style */
.dark-gray .ezy__epgrid5,
.ezy__epgrid5.dark-gray {
  /* Bootstrap variables */
  --bs-body-color: #ffffff;
  --bs-body-bg: rgb(30, 39, 53);

  /* Easy Frontend variables */
  --ezy-card-bg: #162231;
  --ezy-icon-bg: #0b1727;
  --ezy-icon-color: #c2c2c2;
}

/* Dark Block Style */
.dark .ezy__epgrid5,
.ezy__epgrid5.dark {
  /* Bootstrap variables */
  --bs-body-color: #ffffff;
  --bs-body-bg: rgb(11, 23, 39);

  /* Easy Frontend variables */
  --ezy-card-bg: #162231;
  --ezy-icon-bg: #0b1727;
  --ezy-icon-color: #c2c2c2;
}

.ezy__epgrid5-heading {
  font-size: 25px;
  font-weight: 700;
  line-height: 1;
  color: var(--bs-body-color);
}

@media (min-width: 768px) {
  .ezy__epgrid5-heading {
    font-size: 40px;
  }
}

.ezy__epgrid5-row {
  margin-left: -4px;
  margin-right: -4px;
}

.ezy__epgrid5-card {
  background-color: transparent;
}

.ezy__epgrid5-title {
  text-decoration: none !important;
  font-size: 16px;
  line-height: 20px;
  color:black;
  text-align: center;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed content */
  text-overflow: ellipsis; /* Display ellipsis for overflowed content */
  max-width: 100%; /* Adjust as needed */
  display: block; /* Ensure proper behavior inside a container */
}

.ezy__epgrid5-price,
.ezy__epgrid5-ratings {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  color: var(--bs-body-color);
}

.ezy__epgrid5-price {
  color: red;
  text-align: center;
}

.ezy__epgrid5-ratings i {
  color: var(--ezy-rating-color);
}

.ezy__epgrid5-cart i {
  font-size: 16px;
  color: var(--ezy-icon-color);
}

.ezy__epgrid5-cart i:hover {
  color: var(--ezy-theme-color);
}


.ezy__epgrid5-fav-icon i {
  padding: 15px;
  border-radius: 50%;
  background-color: var(--ezy-icon-bg);
  color: var(--ezy-icon-color);
}

.ezy__epgrid5-fav-icon i:hover {
  color: var(--ezy-theme-color);
}

.ezy__epgrid5-btn {
  font-weight: 700;
  padding: 10px 45px;
  background-color: var(--ezy-theme-color);
  border-color: var(--ezy-theme-color);
}

.ezy__epgrid5-btn:hover {
  background-color: rgba(var(--ezy-theme-color-rgb), 0.9);
  border-color: rgba(var(--ezy-theme-color-rgb), 0.9);
}

.ezy__epgrid5 a {
  text-decoration: none;
}

.ezy__epgrid5 img{
  /* max-height: 200px; */
  max-width: 100%;
  width: auto;
}



.ezy__epgrid5-card-img {
  position: relative;
  width: 100%;
}

.ezy__epgrid5-card-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust values as needed */
  border-radius: 8px; /* Optional: rounds the corners */
  background-color: white; /* Optional: adds a background color */
}


.ezy__epgrid5-card-img-full {
  width: 267px !important;
  height: 250px;
  object-fit: cover;
}

.ezy__epgrid5-fav-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  z-index: 1;
  color: black;
  background-color: white; /* White background for the circle */
  border-radius: 50%; /* Makes the container circular */
  width: 30px; /* Define the circle's width */
  height: 30px; /* Define the circle's height */
  display: flex; /* Center the icon inside the circle */
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
}

.ezy__epgrid5-cart-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 20px;
  z-index: 1;
  color: black;
  background-color: white; /* White background for the circle */
  border-radius: 50%; /* Makes the container circular */
  width: 30px; /* Define the circle's width */
  height: 30px; /* Define the circle's height */
  display: flex; /* Center the icon inside the circle */
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
}
.buy-now{
  background-color: #00A550 !important;
  color: white !important;
  border: none !important;
  text-decoration: none !important;
}


.navigation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  gap: 20px;
}

.nav-btn {
  font-size: 50px;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 50px;
  transition: all 0.3s ease;
  border:none !important
}

.nav-btn:hover {
  background-color: #007bff;
  color: white;
  transform: scale(1.1);
}

.previous {
  display: flex;
  align-items: center;
}

.next {
  display: flex;
  align-items: center;
}

