.faq {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 80%;
    margin: 40px auto;
    padding: 30px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.7;
    color: #333;
  }
  
  .section-heading {
    font-size: 2rem; /* Larger font for better hierarchy */
    font-weight: 600; /* Slightly lighter weight for a more modern feel */
    margin-bottom: 20px; /* Adds space below the heading */
    text-align: center; /* Centers the text */
    color: #2a2a2a; /* Dark gray color for the text */
    text-transform: uppercase; /* Adds a clean, professional touch */
    letter-spacing: 1px; /* Adds spacing between letters for better readability */
  }
  
  p {
    margin-bottom: 20px; /* Adds space between questions and answers */
    font-size: 1rem; /* Standard font size */
    color: #555; /* Slightly lighter text for answers */
  }
  
  b {
    color: #2a2a2a; /* Emphasize the questions with a darker shade */
    font-weight: 600; /* Make the questions bold */
  }
  
  hr {
    border: 0;
    height: 1px;
    background: #ddd; /* Light gray divider */
    margin-bottom: 30px; /* Adds space after the horizontal line */
  }
  
  /* Add hover effects for a more interactive feel */

  
  @media screen and (max-width: 768px) {
    .faq {
      width: 90%; /* Make the FAQ section take up more width on smaller screens */
      padding: 20px; /* Adjust padding for mobile screens */
    }
  
    .section-heading {
      font-size: 1.8rem; /* Adjust heading font size on smaller screens */
    }
  
    p {
      font-size: 0.95rem; /* Slightly smaller text for better mobile readability */
    }
  }
  