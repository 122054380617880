.contact{
    background-color: #f9f9f9; /* Light background for the content */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Soft shadow for a modern look */
    border-radius: 12px; /* Rounded corners */
    width: 75%; /* Makes the container take up 75% of the page width */
    margin: 40px auto; /* Centers the container with more space around it */
    padding: 50px; /* Adds padding inside the container */
    font-family: 'Roboto', sans-serif; /* Modern, clean font */
    line-height: 1.8; /* Increases line height for better readability */
    color: #333; /* Dark text color for good contrast */
}


  
  .contact-details {
    text-align: left;
  }
  
  .contact-info {
    /* background: #fff; */
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  }
  
  .contact-info h5 {
    font-size: 1.2rem;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .contact-info p {
    color: #555;
    margin: 5px 0;
  }
  
  .icon-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: brown;
    color: #f8f9fc;
    border-radius: 50%;
    font-size: 20px;
    margin-right: 10px;
  }
  .enquiry-button{
    background-color: #00A550 !important;
    border: none !important;
  }
  .contact-subwriting{
    font-size: 16px !important;
  }