body{
  margin:0;
  padding:0;
  font-family: "Playfair Display", system-ui;
  overflow-x: hidden;
  background-color: whitesmoke !important;
}


/* Apply RTL direction only for Arabic */
.rtl {
  direction: rtl !important;
  text-align: right !important;
}

.ltr {
  direction: ltr !important;
  text-align: left !important;
}
