.whatsapp-button {
    position: fixed;
    bottom: 60px;
    right: 20px;
    z-index: 1000;
  }
  
  .whatsapp-button a img {
    width: 50px; 
    height: 50px; 
    border-radius: 50%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    object-fit: contain;
  }
  
  .whatsapp-button a img:hover {
    transform: scale(1.1);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
  }
  .whatsapp-icon{
    background-color: white;
  }