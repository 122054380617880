/* For screens larger than 991px */
.header-searchbox {
  border-radius: 20px !important;
  margin-right: 10%;
  height: 50px;
  width: 60%;
}

.searcher {
  background-color: white;
  width: 300px;
}
.login-button {
  font-weight: bolder !important;
  text-decoration: none !important;
  color: white !important;
  font-size: 20px !important;
}

.login-button:hover{
  color:brown !important
}
.header {
  background: linear-gradient(to right,#F0E68C, #FFD700, #FEBE10);

}
.header-dropdown {
  width: 350px !important;
  height: 50px !important;
  margin-left: 6%;
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 4px;
  background-color: white !important;
  color: black !important;
  border: none !important;
  box-shadow: 0px 4px 6px rgba(242, 240, 240, 0.5) !important;
}
.dropdown-items {
  width: 350px;
}
.dropdown-items:hover {
  background-color: #eee9f0 !important;
  color: #8E44AD !important;
  font-weight: bold;
}
.dropdown-toggle {
  border: none !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2) !important;
}


/* For smaller screens (max-width: 991px) */
@media (max-width: 991px) {
  .header-options {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  .header-searchbox {
    border-radius: 20px;
    margin-top: 10px;
    width: 100%;
  }

  .login-register {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  /* .login-button {
    margin-left: 10px;
  } */

  .navbar-collapse {
    align-items: flex-end;
  }

  .header-dropdown {
    width: 100%;
    height: 40px;
    margin-left: 0;
    text-align: center;
  }

  .dropdown-items {
    width: 100%;

  }

  .dropdown-toggle {
    font-size: 14px;
  }

  /* Adjust icon sizes on smaller screens */
  .d-flex > .fa {
    font-size: 18px;
  }
}
.header-second {
  background-color: whitesmoke; 
  box-shadow: 0 8px 8px -6px rgba(0, 0, 0, 0.9); /* Black shadow with 30% opacity */
  padding: 10px; /* Add padding for spacing */
  
}
.product-categories {
  display: flex;
  gap: 250px; /* Adjust space between buttons */
  margin-left: 10px; /* Space from the dropdown */
}

.product-categories button {
  padding: 0; /* Adjust button padding if necessary */
  font-size: 20px; /* Adjust font size */
  text-decoration: none;
  font-weight: 900;
  color: black;
}

.garmets-category{
/* padding:200px; */
text-decoration:none ;
color: black;
}
.headlines{
  margin-top: 15px;
  margin-bottom:15px ;
}
/* Container styles */
.dropdown-container {
  position: relative;
  display: inline-block;
  text-align: center;
}


/* Initially hide the dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  top: 70%; /* This ensures the dropdown is directly below the headline */
  left: -78px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding: 10px 0;
  width:200px;
  border-radius: 5px;
}

/* On hover, show the dropdown content */
.dropdown-container:hover .dropdown-content {
  display: block;
}

/* Styling for garments items */
.garments-men {
  margin: 5px;
  cursor: pointer;

}

.garmets-category {
  cursor: pointer;
}
.garments-headline{
  font-weight: bolder;
  position: relative;
  cursor: pointer;
  padding-bottom: 5px;
  transition: all 0.3s ease;
  /* width: 150px; */
  
}
.heading-icons{
  height:100px;
  display: flex;
  align-items: center;
 
}
.icons{
  margin-right: 25px;
  font-size: 20px;
}
.garments-headline:hover {
 color:#8E44AD;
  border-bottom: 3px solid #121112; /* Line under the headline on hover */
  /* width: 50px; */
  font-weight:900 ;
  
}
.Mobile-headlines{
  font-weight: bolder;
  position: relative;
  cursor: pointer;
  padding-bottom: 5px;
  transition: all 0.3s ease;
  width: 150px  ;
  /* background-color: aqua; */
  white-space: nowrap;
}
.Mobile-headlines:hover{
  color:#8E44AD;
  border-bottom: 3px solid #121112; /* Line under the headline on hover */
  font-weight:900 ;
}


.dropdown-content p:hover {
  background-color: #f0f0f0; /* Light background on hover */
}

.dropdown-container:hover .dropdown-content {
  display: block;
}

.language-button{
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  width:200px !important;
  border-radius: 10px !important;
  
}
.language-selector{
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  padding: 5px;
}

.dropdown-image {
  width: 24px; /* Adjust as needed */
  height: 24px;
  margin-right: 8px;
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image fits within the circle */
}



.highlights-container {
  display: flex;
  justify-content: space-evenly;
  
  overflow-x: auto;
  scrollbar-width: none; 
}

.highlights-container::-webkit-scrollbar {
  display: none;  /* For Webkit-based browsers (Chrome, Safari, Edge) */
}

.highlight {
  text-align: center;
  margin: 0 10px;
  padding: 20px;
}

.highlight-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 5px;
  border: 3px solid #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.highlight-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.highlight-title {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}



.search-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px; /* Add some padding around the container */
  box-sizing: border-box;
}

.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 800px; /* Set a max width for better control on larger screens */
  background-color: white;
  border-radius: 15px;
  padding: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.left-side {
  display: flex;
  align-items: center;
  border-radius: 15px !important;
  border: none !important;
}

.select-option-button {
  width:70px !important;
  border-radius: 15px !important;
  border: none !important;
}

.form-design{
  border-radius: 15px !important;
  border: none !important;
  font-weight: bolder !important;
}

.btn-search-K {
  padding: 8px !important;
  background-color: #121112 !important;
  border: none !important;
  color: white !important;
}

.right-side {
  display: flex;
  align-items: center;
}

.search-center {
  flex: 1; /* Allow the search input to take remaining space */
  margin: 0 10px; /* Add spacing on either side */
}
.search-input {
  width: 100%;
  padding: 8px;
  border-radius: 15px;
  border: none; /* Remove the border */
  font-size: 14px;
  outline: none; /* Remove outline on focus if you don't want the default outline */
  box-shadow: none; /* Remove any shadow if applied */
}

search-input:focus {
  outline: none; /* Prevent outline on focus */
}

.delivering-address{
  background-color: rgb(223, 204, 179);
  color:#121112;
  width:100% !important;
  height: 50px !important;
  display: flex;
  align-items: center; /* Ensures proper vertical alignment */
  justify-content: flex-start; /* Aligns content to the start */
}


.delivering-to {
  margin: 0; /* Removes default margin */
  padding: 0; /* Removes default padding */
  text-align: left; /* Ensures text is aligned to the left */
}













.icon-container {
  display: flex;
  align-items: center;
  gap: 16px; /* Adjust this value to increase or decrease the spacing */
}

.icon-container a {
  text-decoration: none;
  color: inherit; /* Keep icon colors consistent with the theme */
}

.headline-row {
  display: flex;
  justify-content: space-around; /* Distributes space evenly */
  align-items: center;
  flex-wrap: nowrap; /* Prevents wrapping */
  padding: 10px;
  background-color: #f8f9fa; /* Optional background */
}

.headline {
  font-size: 1.2rem; /* Adjust as needed */
  font-weight: bold;
  color: #333; /* Text color */
  text-align: center;
}

@media (max-width: 768px) {
  .headline {
    font-size: 1rem; /* Decrease font size on smaller screens */
  }
}

@media (max-width: 480px) {
  .headline-row {
    justify-content: space-between; /* Adjust spacing for smaller screens */
  }
}








.headline-row {
  display: flex;
  justify-content: space-around; /* Distributes space evenly */
  align-items: center;
  flex-wrap: nowrap; /* Prevents wrapping */
  /* padding: 10px; */
  background: linear-gradient(to right,#F0E68C, #FFD700, #FEBE10);
}

.headline {
  font-size: 1rem; /* Adjust as needed */
  font-weight: bolder;
  color: #f9f6f6; /* Text color */
  text-align: center;
  text-decoration: none !important;
}
.headline:hover{
color:brown !important;
text-decoration: none !important;
}

@media (max-width: 768px) {
  .headline {
    font-size: 1rem; /* Decrease font size on smaller screens */
  }
}

@media (max-width: 480px) {
  .headline-row {
    justify-content: space-between; /* Adjust spacing for smaller screens */
  }
}
