.about {
    background-color: #f9f9f9; /* Light background for the content */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Soft shadow for a modern look */
    border-radius: 12px; /* Rounded corners */
    width: 75%; /* Makes the container take up 75% of the page width */
    margin: 40px auto; /* Centers the container with more space around it */
    padding: 50px; /* Adds padding inside the container */
    font-family: 'Roboto', sans-serif; /* Modern, clean font */
    line-height: 1.8; /* Increases line height for better readability */
    color: #333; /* Dark text color for good contrast */
  }
  
  .section-heading {
    font-size: 32px; /* Larger font size for the main heading */
    font-weight: 600; /* Bold for prominence */
    margin-bottom: 20px; /* Adds space below the heading */
    text-align: center; /* Centers the text */
    color: #ffc107; /* Dark gray color for the text */
    text-transform: uppercase; /* Capitalizes the main heading */
    letter-spacing: 2px; /* Adds spacing between letters */
  }
  
  .sub-headings {
    font-size: 24px; /* Larger subheadings */
    font-weight: 500; /* Bold subheadings */
    color: #333; /* Slightly darker color */
    margin-top: 30px;
    margin-bottom: 15px;
    padding-bottom: 5px;
  }
  
  .short-details {
    font-size: 16px; /* Standard size for paragraph text */
    margin-bottom: 15px; /* Adds space between paragraphs */
    text-align: justify; /* Justifies the text for a cleaner look */
    color: #555; /* Slightly lighter text color for contrast */
  }
  
  .offer-section, .choose-us {
    margin-top: 20px;
  }
  
  .short-details b {
    color: #ffc107; /* Highlight key words in blue */
  }
  
  hr {
    border: 1px solid #ddd; /* A soft gray line */
    margin-top: 20px; /* Adds space above the line */
    margin-bottom: 20px; /* Adds space below the line */
  }
  
  @media (max-width: 768px) {
    .about {
      width: 90%; /* Adjusts the width for smaller screens */
      padding: 30px; /* Reduces padding for smaller screens */
    }
  
    .section-heading {
      font-size: 28px; /* Smaller font size for smaller screens */
    }
  
    .sub-headings {
      font-size: 20px; /* Smaller subheading font size */
    }
  
    .short-details {
      font-size: 14px; /* Smaller font size for smaller screens */
    }
  }
  