.terms-conditions{
  margin: 50px auto;
  padding: 30px; /* Padding for inner spacing */
  width: 90%; /* Flexible width for responsiveness */
  max-width: 1200px; /* Restrict maximum width */
  background-color: #f9f9f9;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  line-height: 1.6;
  font-family: 'Roboto', sans-serif;
  color: #333;
  box-sizing: border-box; /* Include padding in the total width */
}

/* Adjust for smaller screens */
@media (max-width: 768px) {
  .terms-conditions {
      padding: 20px;
      width: 95%; /* Increase flexibility for small screens */
  }
}
.headline-terms {
  position: relative;
  text-align: center;
  font-size: 24px; /* Larger and more prominent font size */
  color:  #ffc107; /* Add a touch of color to the headline */
  padding-bottom: 15px;
  font-weight: 700; /* Bold text for emphasis */
}

.headline-terms::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60%; /* Reduce line width for a balanced look */
  height: 2px; /* Thicker line for better visibility */
  background-color:  #ffc107; /* Match headline color */
}

.short-headings {
  font-size: 20px;
  font-weight: 600;
  color: #333; /* Subtle contrast with the background */
  margin-top: 20px;
}

.sub-writings-terms {
  font-size: 16px;
  margin-top: 10px;
  color: #555; /* Softer shade for less emphasis */
}

ul {
  padding-left: 20px;
  margin-top: 10px;
}

ul li {
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

li::marker {
  color:  #ffc107; /* Match design color */
  font-size: 1.2em; /* Larger bullet points */
}

a {
  color:  #ffc107; /* Add consistent link styling */
  text-decoration: none;
}

a:hover {
  text-decoration: underline; /* Visual cue on hover */
}

@media (max-width: 768px) {
  .privacy-policy {
      padding: 20px;
  }

  .headline-terms {
      font-size: 20px;
  }

  .short-headings {
      font-size: 18px;
  }

  .sub-writings-terms {
      font-size: 14px;
  }
}
