.verification-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 20px 0;
    /* box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); */

  }
  .verify-container{
    /* display: flex; */
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
    padding: 25px;
    border-radius: 10px;
   

  }
  
  .verification-input {
  margin: 5px;
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    text-align: center !important;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .verification-input:focus {
    border-color: #4caf50;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
  }
  .verify-code{
display: flex;
justify-content: center;
align-items: center;

  }
  .verifying-button{
    background-color: #4caf50 !important;
    color:white !important;
    font-weight: bolder !important;
    border:none !important;
  }