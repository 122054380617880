.footer{
    background: linear-gradient(to right,#F0E68C, #FFD700, #FEBE10);
    color: BLACK;
    /* padding-top: 5%; */
    margin-top: 10px;
}
.text-light{
    text-decoration: none;
}
.footer-form{
    border-radius: 5px !important;
}
.footer-icons{
    color:black !important;
    text-decoration:none !important;
   
}
.foooter-logo{
    width: 100px !important;
    height:120px !important
}
.dubai-flag{
    width:20px;
    height:20px;
    margin-right: 5px;
}
.copy-right{
   text-align: center;
   justify-content: flex-end;
   align-items: flex-end;
}
.footer-logo{
  width:100px !important;
  height:120px !important
}

/* For screens smaller than 768px (tablet and mobile) */
@media (max-width: 768px) {
    .footer {
      padding: 20px;
      text-align: center; /* Center align content for smaller screens */
    }
  
    .footer .dubai-flag {
      margin: 0 auto;
    }
  
    .foooter-logo {
      margin: 0 auto 10px;
      display: block;
    }
  
    .copy-right {
      text-align: center; /* Center the copyright text */
    }
  
    .footer-icons {
      margin: 0 10px;
      display: inline-block; /* Keep icons in a single line */
    }
  
    .footer ul {
      padding: 0;
    }
  
    .footer ul li {
      display: inline-block; /* Display links in a row */
      margin: 0 10px;
    }
  
    .footer h5 {
      margin-top: 20px;
      font-size: 1rem; /* Reduce heading size */
    }
  }
  
  /* For screens smaller than 576px (mobile) */
  @media (max-width: 576px) {
    .footer {
      padding: 15px;
    }
  
    .footer h5 {
      font-size: 0.9rem; /* Further reduce heading size */
    }
  
    .footer ul li {
      display: block; /* Stack links vertically */
      margin: 10px 0;
    }
  
    .dubai-flag {
      width: 15px; /* Adjust flag size */
      height: 15px;
    }
  
    .foooter-logo {
      width: 80px; /* Reduce logo size */
      height: 100px;
    }
  
    .footer-icons {
      font-size: 1rem; /* Adjust icon size */
    }
  }

 
  .footer-goto-icons:hover {
    color: white !important; /* Change color on hover */
    transform: scale(1.1); /* Slightly enlarge the icon on hover */
}
.footer-goto-icons{
font-size: 20px;
color:black !important;
}
.footer-socialmedia{
  font-size: 25px !important;
}






