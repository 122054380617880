/* Remove any margins or paddings between columns if they cause issues */
.row {
    margin-left: 0;
    margin-right: 0;
  }
  
  /* Ensure the sticky cart summary stays on the page */
  .sticky-top {
    position: sticky;
    top: 20px;
  }
  .cart-checkout{
    background-color: green !important;
    color: white !important;
    border: none !important;
  }
  .cart-no-products{
    color: red !important;
    font-weight: bolder;
    text-transform: uppercase;
  }