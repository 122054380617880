
.ezy__signin2 {
	/* Bootstrap variables */
	--bs-body-color: #333b7b;
	--bs-body-bg: rgb(255, 255, 255);

	/* Easy Frontend variables */
	--ezy-theme-color: rgb(13, 110, 253);
	--ezy-theme-color-rgb: 13, 110, 253;
	--ezy-form-card-bg: #ffffff;
	--ezy-form-card-shadow: none;

	background-color: var(--bs-body-bg);
}

/* Gray Block Style */
.gray .ezy__signin2,
.ezy__signin2.gray {
	/* Bootstrap variables */
	--bs-body-bg: rgb(246, 246, 246);

	/* Easy Frontend variables */
	--ezy-form-card-bg: #ffffff;
	--ezy-form-card-shadow: 0 16px 24px rgba(209, 223, 247, 0.25);
}

/* Dark Gray Block Style */
.dark-gray .ezy__signin2,
.ezy__signin2.dark-gray {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(30, 39, 53);
	--bs-dark-rgb: 255, 255, 255;

	/* Easy Frontend variables */
	--ezy-form-card-bg: #253140;
	--ezy-form-card-shadow: none;
}

/* Dark Block Style */
.dark .ezy__signin2,
.ezy__signin2.dark {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(11, 23, 39);
	--bs-dark-rgb: 255, 255, 255;

	/* Easy Frontend variables */
	--ezy-form-card-bg: #162231;
	--ezy-form-card-shadow: none;
}

.ezy__signin2 .container {
	min-height: 100vh;
}

.ezy__signin2-heading {
	font-weight: bold;
	font-size: 25px;
	line-height: 25px;
	color: var(--bs-body-color);
}

.ezy__signin2-bg-holder {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 150px;
	width: 100%;
	float: right;
}

@media (min-width: 991px) {
	.ezy__signin2-bg-holder {
		width: 50vw;
	}
}

.ezy__signin2-form-card {
	background-color: transparent;
	border: none !important;
	box-shadow: var(--ezy-form-card-shadow);
	/* border-radius: 15px; */
}

.ezy__signin2-form-card * {
	color: var(--bs-body-color);
}

.ezy__signin2 .form-control {
	min-height: 48px;
	line-height: 40px;
	border-color: transparent;
	background: rgba(163, 190, 241, 0.14);
	border-radius: 10px;
	color: var(--bs-body-color);
}

.ezy__signin2 .form-control:focus {
	border-color: var(--ezy-theme-color);
	box-shadow: none;
}

.ezy__signin2-btn-submit {
	padding: 12px 30px;
	background-color: green !important;
	color: #ffffff !important;
}

.ezy__signin2-btn-submit:hover {
  background-color: #ffffff !important;
  color:green !important;
}

.ezy__signin2-btn {
	padding: 12px 30px;
}

.ezy__signin2-btn,
.ezy__signin2-btn * {
	color: #ffffff;
}

.ezy__signin2-btn:hover {
	color: #ffffff;
}

.ezy__signin2-or-separator {
	position: relative;
}

.ezy__signin2-or-separator hr {
	border-color: var(--bs-body-color);
	opacity: 0.15;
}

.ezy__signin2-or-separator span {
	background-color: var(--ezy-form-card-bg);
	color: var(--bs-body-color);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	opacity: 0.8;
}
.login-page-logo{
  width:525px;
  height:150px;
  
}
.logodiv{
  display:flex !important;
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
}
.forget-password-btn{
	text-decoration: none !important;
}

/* Make logo responsive */
.login-page-logo {
	width: 100%; /* Make logo fill the width of the parent container */
	max-width: 525px; /* Max width to keep the logo from growing too large */
	height: auto; /* Maintain the aspect ratio of the logo */
  }
  
  /* Style for logodiv to center the logo */
  .logodiv {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	text-align: center !important;
	padding: 20px; /* Add some padding for smaller screens */
  }
  
  /* Optional: Make the logo smaller on very small screens */
  @media (max-width: 576px) {
	.login-page-logo {
	  max-width: 80%; /* Adjust to 80% of parent container */
	}
  }
  

  /* General styling */
.login-page-logo {
	width: 525px;
	height: 150px;
	object-fit: contain; /* Ensures the image scales proportionally */
  }
  
  .logodiv {
	display: flex !important;
	text-align: center !important;
	justify-content: center !important;
	align-items: center !important;
  }
  
  /* Responsive Styling */
  @media (max-width: 1200px) {
	.login-page-logo {
	  width: 400px; /* Reduce logo size on medium screens */
	  height: auto; /* Maintain aspect ratio */
	}
  }
  
  @media (max-width: 992px) {
	.login-page-logo {
	  width: 300px; /* Further reduce the logo size for tablets */
	  height: auto; /* Maintain aspect ratio */
	}
  }
  
  @media (max-width: 768px) {
	.login-page-logo {
	  width: 250px; /* Smaller logo on mobile */
	  height: auto; /* Maintain aspect ratio */
	}
  }
  
  @media (max-width: 576px) {
	.login-page-logo {
	  width: 200px; /* Even smaller logo for very small screens */
	  height: auto; /* Maintain aspect ratio */
	}
  }
  
  @media (max-width: 480px) {
	.logodiv {
	  margin-top: 20px; /* Add some space on very small screens */
	}
  
	.login-page-logo {
	  width: 180px; /* Further reduce logo size */
	  height: auto; /* Maintain aspect ratio */
	}
  }
  