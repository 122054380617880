.ezy__epgrid5 {
    --bs-body-color: #23262f;
    --bs-body-bg: #ffffff;
    --ezy-theme-color: rgb(13, 110, 253);
    --ezy-theme-color-rgb: 13, 110, 253;
    --ezy-card-bg: #fff;
    --ezy-img-bg: #edf8eb;
    --ezy-icon-bg: #f6f8fa;
    --ezy-icon-color: #23262f;
    --ezy-button-color: #fff;
    --ezy-rating-color: #eec200;
    background-color: var(--bs-body-bg);
    padding: 60px 0;
  }
  
  @media (min-width: 768px) {
    .ezy__epgrid5 {
      padding: 100px 0;
    }
  }
  
  .ezy__epgrid5-row {
    margin-left: -4px;
    margin-right: -4px;
  }
  
  .ezy__epgrid5-card {
    background-color: transparent;
  }
  
  .ezy__epgrid5-title {
    text-decoration: none !important;
    font-size: 16px;
    line-height: 20px;
    color: black;
    text-align: center;
    overflow: hidden;
    max-width: 100%;
  }
  
  .ezy__epgrid5-price,
  .ezy__epgrid5-ratings {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    color: var(--bs-body-color);
  }
  
  .ezy__epgrid5-price {
    color: red;
    text-align: center;
  }
  
  .ezy__epgrid5-ratings i {
    color: var(--ezy-rating-color);
  }
  
  .ezy__epgrid5-cart i {
    font-size: 16px;
    color: var(--ezy-icon-color);
  }
  
  .ezy__epgrid5-cart i:hover {
    color: var(--ezy-theme-color);
  }
  
  .ezy__epgrid5-fav-icon i {
    padding: 15px;
    border-radius: 50%;
   background-color: black !important;
  }
  
  .ezy__epgrid5-fav-icon i:hover {
    color: var(--ezy-theme-color);
  }
  
  .ezy__epgrid5-btn {
    font-weight: 700;
    padding: 10px 45px;
    background-color: var(--ezy-theme-color);
    border-color: var(--ezy-theme-color);
  }
  
  .ezy__epgrid5-btn:hover {
    background-color: rgba(var(--ezy-theme-color-rgb), 0.9);
    border-color: rgba(var(--ezy-theme-color-rgb), 0.9);
  }
  
  .ezy__epgrid5 a {
    text-decoration: none;
  }
  
  .ezy__epgrid5 img {
    max-width: 100%;
    width: auto;
  }
  
  .ezy__epgrid5-card-img {
    position: relative;
    width: 100%;
  }
  
  .ezy__epgrid5-card-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background-color: white;
  }
  
  .ezy__epgrid5-card-img-full {
    width: 267px !important;
    height: 250px;
    object-fit: cover;
  }
  
  .ezy__epgrid5-fav-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 20px;
    z-index: 1;
    color: black;
    background-color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ezy__epgrid5-cart-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 20px;
    z-index: 1;
    color: black;
    background-color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .buy-now {
    background-color: #00a550 !important;
    color: white !important;
    border: none !important;
    text-decoration: none !important;
  }
  