.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px;
}

.product-carousel .carousel-item img,
.grid-images .product-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.grid-images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.product-name {
    /* font-size: 2rem; */
    font-weight: bold;
    color: #333;
}

.product-details {
    font-size: 16px;
    color: #777;
    margin-bottom: 10px;
}

.product-price {
    font-size: 1.5rem;
    font-weight: bold;
    color: red !important;
    margin-top: 20px;
}

.original {
    font-size: 1rem;
    color: #aaa;
    margin-left: 10px;
    text-decoration: line-through;
}

.product-colors,
.product-size {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 10px;
}

.colors, .select-size {
    gap: 10px;
    display: flex;
}

.button-color, .button-size {
    margin: 5px 10px 5px 0;
    border-radius: 20px;
}

.buying-button {
    font-size: 1rem;
    border-radius: 15px;
    background-color: #00A550 !important;
    border: none !important;

    width:250px;
    height: 50px;

}
.buyin-button{
    font-size: 1rem;
    border-radius: 15px;
    background-color: #00A550 !important;
    border: none !important;
  
    width:250px;
    height: 50px;
}



.quantity-selector {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: 0;
}

.product-description {
    padding: 15px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

@media (min-width: 768px) {
    .product-name, .product-details, .product-price {
        text-align: left;
    }
}

@media (max-width: 760px) {
    .product-name, .product-details, .product-price, .product-colors {
        text-align: center;
    }
}
/* Add this to your viewproducts.css file */
@media (max-width: 576px) {
  .product-image {
    display: none;
  }

  .card-img {
    display: none;
  }
}

.button-wishlist {
    width: 40px; /* Adjust size as needed */
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Creates a soft shadow */
    padding: 0; /* Ensure padding doesn’t affect the circle shape */
    border:none !important
  }
  
  .line {
    display: block;
  }
  .products-image{
    width:100% !important;
    height:600px !important
  }
  